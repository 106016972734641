@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.chat_textarea:focus {
  border-color: var(--tw-border-color-focus);
}

#coupon::placeholder {
  font-size: 15px;
}

#coupon:focus {
  outline: none;
}

.package-head::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #ff69b4;
  border: none;
  animation: background-color 0.5s;
  z-index: -1;
  border-radius: 10px;
}

@keyframes background-color {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.blurred {
  filter: blur(10px);
  transition: filter 0.3s;
}

.custom-toast {
  color: white;
}

.custom-toast .Toastify__close-button {
  color: white;
}

.parent-container {
  width: 300px;
  height: 300px;

  > div {
    height: 100%;
    width: 100%;
    max-height: unset;
    max-width: unset;
  }
}

.Toastify__toast-container {
  width: fit-content;
  max-width: 100%;
  padding: 15px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .parent-container {
    width: 250px;
    height: 250px;
  }
}

.blur {
  filter: blur(15px);
}