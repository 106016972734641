@import 'variables';
$color_1: rgba(67, 67, 67, 1);
$color_2: #fff;
$color_3: rgba(60, 60, 60, 1);
$color_4: $theme_color;
$color_5: rgba(83, 83, 83, 1);
$color_6: rgba(28, 28, 28, .51);
$color_7: rgba(62, 62, 62, .53);
$color_8: rgba(62, 62, 62, .48);
$color_9: rgba(70, 70, 70, 1);
$color_10: rgba(0, 0, 0, .5);
$color_11: #FFE200;
$color_12: rgba(0, 0, 0, .56);
$color_13: rgba(0, 0, 0, .24);
$color_14: #000;
$color_15: rgba(255,255,255,.41);
$color_16: rgba(0, 0, 0, .41);
$color_17: #b61648;
$color_18: #D50137;
$color_19: rgba(28, 28, 28, .5);
$color_20: rgba(52, 159, 253, 1);
$color_21: rgba(255,255,255,1);
$color_22: rgba(0,0,0,.5);
$color_23: rgba(0, 0, 0, .21);
$background-color_1: $theme_color;
$border-color_1: $theme_color;

/* FAST CHAT */
/* /*
.footer .newMessages{
	position:fixed;
	width:200px;
	right:50px;
	bottom:0;
	background: transparent linear-gradient(180deg, #8a997b 0%, rgba(180, 23, 71, 1) 100%) 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	color:#fff;
	font-weight:bold;
	padding:10px;
	border-radius: 6px 6px 0px 0px;
	text-align:center;
	display:block;
	font-size:16px;
} */
body {
	background: rgba(246, 246, 247, 1);
	color: $color_1;
	padding-bottom: 35px;
}
html {
	background: rgba(246, 246, 247, 1);
	color: $color_1;
	padding-bottom: 35px;
}
a {
	color: $color_1;
	text-decoration: none;
	&:hover {
		color: $color_1;
		text-decoration: none;
	}
	&:focus {
		color: $color_1;
		text-decoration: none;
	}
	&:active {
		color: $color_1;
		text-decoration: none;
	}
}
.badge {
	background: $theme_color;
	border-radius: 10px;
	color: $color_2;
	margin-left: 5px;
	margin-top: 0;
}
.btn-custom {
	border-radius: 26px;
	padding: 10px 35px;
}
.btn-red {
	background: $theme_color;
	border: 1px solid rgba(255, 255, 255, 1);
	color: $color_2 !important;
	&:hover {
		background: $theme_color;
		border: 1px solid rgba(255, 255, 255, 1);
		color: $color_2 !important;
	}
	&:focus {
		background: $theme_color;
		border: 1px solid rgba(255, 255, 255, 1);
		color: $color_2 !important;
	}
	&:active {
		background: $theme_color;
		border: 1px solid rgba(255, 255, 255, 1);
		color: $color_2 !important;
	}
}
.btn-green {
	background: #4D8F56;
	border: 1px solid rgba(255, 255, 255, 1);
	color: $color_2;
	&:hover {
		background: #4D8F56;
		border: 1px solid rgba(255, 255, 255, 1);
		color: $color_2;
	}
	&:focus {
		background: #4D8F56;
		border: 1px solid rgba(255, 255, 255, 1);
		color: $color_2;
	}
	&:active {
		background: #4D8F56;
		border: 1px solid rgba(255, 255, 255, 1);
		color: $color_2;
	}
}
.btn-fb {
	background: rgba(78, 113, 168, 1);
	border: 1px solid rgba(255, 255, 255, 1);
	&:hover {
		background: rgba(78, 113, 168, 1);
		border: 1px solid rgba(255, 255, 255, 1);
	}
	&:focus {
		background: rgba(78, 113, 168, 1);
		border: 1px solid rgba(255, 255, 255, 1);
	}
	&:active {
		background: rgba(78, 113, 168, 1);
		border: 1px solid rgba(255, 255, 255, 1);
	}
}
.btn-google {
	background: rgba(234, 234, 234, 1);
	border: 1px solid rgba(255, 255, 255, 1);
	color: $color_3 !important;
	&:hover {
		background: rgba(234, 234, 234, 1);
		border: 1px solid rgba(255, 255, 255, 1);
		color: $color_3 !important;
	}
	&:focus {
		background: rgba(234, 234, 234, 1);
		border: 1px solid rgba(255, 255, 255, 1);
		color: $color_3 !important;
	}
	&:active {
		background: rgba(234, 234, 234, 1);
		border: 1px solid rgba(255, 255, 255, 1);
		color: $color_3 !important;
	}
}
.btn-white {
	background: #fff;
	color: $color_4;
	&:hover {
		background: #fff;
		color: $color_4;
	}
	&:focus {
		background: #fff;
		color: $color_4;
	}
	&:active {
		background: #fff;
		color: $color_4;
	}
}
.btn-grey {
	background: rgba(214, 224, 230, 1);
	color: $color_5;
	&:hover {
		background: rgba(214, 224, 230, 1);
		color: $color_5;
	}
	&:focus {
		background: rgba(214, 224, 230, 1);
		color: $color_5;
	}
	&:active {
		background: rgba(214, 224, 230, 1);
		color: $color_5;
	}
}
.btn-message {
	background: $theme_color;
	color: $color_2;
	&:hover {
		background: $theme_color;
		color: $color_2;
	}
	&:focus {
		background: $theme_color;
		color: $color_2;
	}
	&:active {
		background: $theme_color;
		color: $color_2;
	}
}
.header {
	background: #fff;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
	padding: 15px;
	position: relative;
	z-index: 2;
	.logo {
		width: 90px;
	}
	.links {
		display: flex;
		a {
			margin-right: 35px;
			color: $color_6;
			display: flex;
			align-items: center;
			text-decoration: none;
		}
		i {
			font-size: 20px;
			color: $color_4;
			margin-right: 10px;
		}
	}
	.userImage {
		width: 43px;
		height: 43px;
		border-radius: 50%;
		object-fit: cover;
	}
}
.sidebar {
	background: #fff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
	padding: 25px;
	height: 100%;
	position: relative;
	z-index: 1;
	.userInfo {
		img {
			width: 100%;
			border-radius: 50%;
		}
		small {
			color: $color_7;
		}
		border-bottom: 1px solid rgba(112, 112, 112, .2);
		padding-bottom: 25px;
		padding-top: 10px;
	}
	.menu {
		padding-top: 25px;
		strong {
			color: $color_8;
		}
		.link {
			padding: 15px 15px;
			color: $color_9;
			display: block;
			i {
				margin-right: 5px;
				width: 25px;
			}
		}
		.link.active {
			background: $theme_color;
			color: $color_2;
			border-radius: 5px;
		}
	}
}
.dashboard {
	.userImages {
		display: flex;
		padding-bottom: 25px;
		a {
			width: 20%;
		}
		img {
			width: 100%;
			padding: 10px;
			border-radius: 50%;
		}
	}
}
.box {
	background: #fff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
	padding: 0px 15px;
	.head {
		padding: 15px 0px;
		border-bottom: 1px solid rgba(112, 112, 112, .23);
		h3 {
			margin: 0;
			font-size: 18px;
			font-weight: lighter;
			strong {
				color: $color_4;
			}
		}
	}
	.head.noborder {
		border: none;
	}
	.body {
		padding: 15px 0px;
	}
	a {
		color: $color_4;
		font-size: 14px;
		text-decoration: none;
	}
}
.messages {
	.messageBox {
		display: block;
		strong {
			font-size: 16px;
			color: $color_1;
		}
		img {
			width: 100%;
			border-radius: 50%;
		}
		small {
			color: $color_7;
		}
		i {
			border-radius: 10px;
			border: 1px solid $theme_color;
			padding: 15px;
			color: $color_4;
			font-size: 25px;
		}
	}
}
.sideboxes {
	.suggestions {
		.users {
			display: block;
			strong {
				font-size: 16px;
				color: $color_1;
			}
		}
		img {
			width: 100%;
			border-radius: 50%;
		}
		small {
			color: $color_7;
		}
		.col-md-4 {
			padding-right: 0;
		}
	}
	.survey {
		form {
			margin-top: 15px;
			label {
				margin-left: 5px;
			}
		}
		.btn-custom {
			padding: 5px 35px;
		}
	}
}
.footer {
	background: #fff;
	padding: 15px;
	margin-top: 100px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
	margin-left: -25px;
	.newMessages {
		position: fixed;
		width: 200px;
		right: 50px;
		bottom: 0;
		background: transparent linear-gradient(180deg, $theme_color 0%, rgba(180, 23, 71, 1) 100%) 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
		color: $color_2;
		font-weight: bold;
		padding: 10px;
		border-radius: 6px 6px 0px 0px;
		text-align: center;
		display: block;
		font-size: 16px;
	}
	img {
		width: 67px;
	}
	a {
		color: $color_10;
		margin-left: 15px;
		text-decoration: none;
		font-size: 12px;
	}
}
.menu-toggler {
	display: none;
}
.shop {
	.shopBox {
		background: transparent linear-gradient(90deg, #D91853 0%, #6D0C2A 100%) 0% 0% no-repeat padding-box;
		border-radius: 5px;
		padding: 15px;
		color: $color_2;
		margin-bottom: 120px;
		.borderLeft {
			border-left: 5px solid #FFE200;
			padding-left: 15px;
		}
		h4 {
			font-size: 19px;
			strong {
				color: $color_11;
				font-weight: bold;
			}
		}
		small {
			font-size: 12px;
			display: block;
			margin-top: 15px;
			margin-bottom: 15px;
		}
		.paymentBox {
			background: #fff;
			padding: 5px;
			box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
			border-radius: 5px;
			margin-bottom: -100px;
			margin-top: 10px;
			display: flex;
			flex-flow: wrap;
			img {
				padding: 15px;
				width: 80%;
			}
			img.height {
				height: 75px;
				width: auto;
			}
			.provider {
				width: 50%;
				text-align: center;
				&:nth-child(odd) {
					border-right: 1px solid rgba(112, 112, 112, .21);
					border-bottom: 1px solid rgba(112, 112, 112, .21);
				}
				&:nth-child(even) {
					border-bottom: 1px solid rgba(112, 112, 112, .21);
				}
				&:last-child {
					border: none;
				}
				&:nth-last-child(2) {
					border-bottom: none;
				}
			}
		}
		.paymentBox.paysafe {
			display: block;
			img {
				width: 100%;
				padding: 5px;
			}
			.provider {
				width: 100%;
			}
		}
	}
	.shopBox.paysafe {
		background: transparent linear-gradient(270deg, rgba(1, 167, 243, 1) 0%, rgba(90, 211, 255, 1) 100%) 0% 0% no-repeat padding-box;
	}
	.shopBox.code {
		background: rgba(250, 166, 25, 1) 0% 0% no-repeat padding-box;
		margin-bottom: 0;
		.btn-red {
			border: none;
		}
	}
}
.profil {
	.profilInfo {
		.profilImage {
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
			background: #fff;
			padding: 15px;
			border-radius: 10px;
			img {
				width: 100%;
				border-radius: 10px;
			}
		}
		.profilImageInfo {
			text-align: center;
			padding: 15px 0px;
			font-size: 12px;
			color: $color_12;
			i {
				color: $color_4;
				font-size: 18px;
			}
		}
		.profilDetails {
			i {
				color: $color_13;
				font-size: 25px;
				margin-left: 10px;
			}
			.body {
				.col-6 {
					&:nth-child(odd) {
						padding-right: 5px;
					}
					&:nth-child(even) {
						padding-left: 5px;
					}
				}
			}
			.profilDetailsBox {
				background: rgba(170, 170, 170, .27);
				padding: 10px;
				margin-bottom: 10px;
				small {
					display: block;
					font-size: 10px;
					text-transform: uppercase;
				}
			}
		}
	}
	.profilActions {
		margin: 35px 0px;
		display: flex;
		margin-top: 15px;
		justify-content: flex-end;
		.btn-custom {
			margin: 0px 3px;
			border-radius: 5px;
		}
		.btn-message {
			width: 100%;
		}
	}
	.profilDistance {
		img {
			width: 100%;
			border-radius: 10px;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
		}
	}
	.profilPhotos {
		img {
			border-radius: 10px;
			width: 100%;
			margin-bottom: 15px;
		}
	}
}
.moreUser {
	img {
		border-radius: 10px;
		width: 100%;
		margin-bottom: 15px;
	}
}
.suche {
	.sucheBox {
		border-radius: 10px;
		position: relative;
		margin-bottom: 25px;
		display: block;
		img {
			width: 100%;
			border-radius: 10px;
		}
		.onpic {
			position: absolute;
			bottom: 0;
			width: 100%;
			background: rgba(0, 0, 0, .6);
			padding: 10px;
			color: $color_2;
			font-size: 14px;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
			strong {
				display: block;
			}
			i {
				border-radius: 50%;
				font-size: 17px;
				color: $color_2;
				background: $theme_color;
				padding: 8px;
				border: 1px solid #fff;
			}
		}
	}
}
.chat {
	.head {
		img {
			width: 100%;
			border-radius: 50%;
		}
		h3 {
			font-weight: bold;
			color: $color_14;
		}
		i {
			color: $color_13;
			font-size: 25px;
			margin-left: 10px;
		}
	}
	.chatBox {
		height: 600px;
		overflow: auto;
		.bubble {
			border-radius: 20px;
			padding: 15px;
			max-width: 70%;
			margin-bottom: 15px;
		}
		.other {
			background: rgba(238, 238, 238, 1);
		}
		.own {
			background: $theme_color;
			color: $color_2;
			text-align: right;
			margin-left: auto;
			small {
				color: $color_15;
			}
		}
		small {
			color: $color_16;
			font-size: 12px;
			display: block;
			margin-top: 10px;
		}
	}
	.chatSend {
		border-top: 1px solid rgba(112, 112, 112, .21);
		padding: 15px 0px;
		.btn-custom {
			width: 100%;
			padding: 7px;
		}
		strong {
			color: $color_4;
			font-weight: bold;
			display: inline-block;
			margin-right: 25px;
			margin-top: 15px;
		}
	}
}
.page-item.active {
	.page-link {
		background-color: $background-color_1;
		border-color: $border-color_1;
	}
}
.page-link {
	color: $color_4;
	&:hover {
		color: $color_17;
	}
}
.progress-bar {
	background-color: $background-color_1;
}
.fast-chat {
	max-width: 330px;
	width: 100%;
	max-height: 450px;
	height: 100%;
	box-shadow: 0px 3px 6px #00000029;
	border: 1px solid #C7C7C7;
	border-radius: 10px 10px 0px 0px;
	background: #fff;
	position: fixed;
	bottom: 0;
	right: 50px;
	.fast-head {
		padding: 10px 15px;
		display: flex;
		align-items: center;
		background: transparent linear-gradient(180deg, $theme_color 0%, rgba(180, 23, 71, 1) 100%) 0% 0% no-repeat padding-box;
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
		color: $color_2;
		.fast-color {
			color: $color_2;
		}
	}
	.fast-head.clicktoshow {
		cursor: pointer;
	}
	.fast-flex-auto {
		flex: auto;
	}
	.fast-color {
		color: $color_18;
		display: flex;
	}
	.fast-chat-number {
		background: #fff;
		color: $color_18;
		font-size: 12px;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		line-height: 20px;
		display: inline-block;
		text-align: center;
		margin-left: 0;
		margin-right: 10px;
	}
	.fast-close {
		text-align: right;
		display: inline-block;
		float: right;
		font-size: 18px;
		color: $color_2;
		cursor: pointer;
		font-weight: bold;
		position: absolute;
		right: 15px;
		top: 8px;
	}
	.fast-up {
		text-align: right;
		display: inline-block;
		float: right;
		font-size: 18px;
		color: $color_2;
		cursor: pointer;
		font-weight: bold;
		position: absolute;
		right: 15px;
		top: 8px;
	}
	.fast-box {
		border-bottom: 1px solid rgba(112, 112, 112, .35);
		padding: 15px;
		cursor: pointer;
		.row {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.fast-image {
			border-radius: 50%;
			width: 50px;
			height: 50px;
			object-fit: cover;
		}
		.col-md-9 {
			padding: 0;
			padding-right: 15px;
		}
		.fast-time {
			float: right;
			font-size: 11px;
		}
		.fast-msg {
			width: 200px;
			display: inline-block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.fast-name {
			font-size: 15px;
			width: 140px;
			display: inline-block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-bottom: -3px;
		}
	}
	.fast-flexbox {
		display: flex;
	}
	.fast-body {
		overflow: auto;
		padding-bottom: 40px;
		height: 400px;
	}
	.fast-alle-chats {
		position: absolute;
		bottom: 0;
		width: 100%;
		color: $color_2;
		background: transparent linear-gradient(180deg, #d71652 0%, #b41647 100%) 0% 0% no-repeat padding-box;
		padding: 10px;
		font-size: 14px;
		text-align: center;
		left: 0;
		right: 0;
		cursor: pointer;
	}
}
.fast-message-box {
	.fast-head {
		align-items: center;
		border-bottom: 1px solid rgba(112, 112, 112, .35);
		.fast-flexbox {
			flex: auto;
			div {
				&:first-child {
					display: flex;
					align-items: center;
				}
			}
			.fast-flex-auto {
				flex: auto;
				display: flex;
				align-items: center;
				flex-flow: row-reverse;
			}
		}
		.fa-chevron-left {
			color: $color_2;
			font-size: 25px;
			cursor: pointer;
		}
		img {
			width: 50px;
			height: 50px;
			border-radius: 50%;
			object-fit: cover;
			margin-left: 15px;
			margin-right: 15px;
		}
	}
	.fast-message-content {
		padding: 15px;
	}
	.fast-message {
		padding: 10px 15px;
		border-radius: 20px;
		max-width: 80%;
		min-width: 50%;
		margin-bottom: 15px;
		.fast-message-time {
			display: block;
			font-size: 12px;
			text-align: right;
			color: $color_19;
			margin-top: 5px;
		}
		.fast-message-status.seen {
			color: $color_20;
		}
	}
	.fast-message.fast-message-other {
		background: #fff;
		border: 1px solid #BFBFBF;
		float: left;
	}
	.fast-message.fast-message-own {
		background: $theme_color;
		color: $color_2;
		float: right;
		.fast-message-time {
			color: $color_21;
		}
		.fast-message-status.seen {
			color: $color_2;
		}
	}
	.fast-message-send {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		background: #fff;
		padding: 10px;
		.form-control {
			background: rgba(240, 240, 240, 1);
			color: $color_22;
			border-radius: 60px;
			border: none;
			width: 100%;
			box-shadow: none;
			width: 240px;
		}
		.fast-flexbox {
			align-items: center;
		}
		.fast-send {
			color: $color_4;
			margin-left: 10px;
			cursor: pointer;
		}
	}
	.fast-close {
		top: 25px;
	}
}
.color-red {
	color: $color_4;
}
.show-mobile {
	display: none;
}
@media only screen and (max-width:1200px) {
	.sidebar {
		.menu {
			.link {
				i {
					width: 20px;
					margin-right: 0;
				}
				font-size: 13px;
			}
		}
		.userInfo {
			font-size: 14px;
		}
	}
	.sideboxes {
		.survey {
			.btn-custom {
				padding: 5px;
				width: 100%;
			}
		}
	}
	.profil {
		.profilActions {
			flex-flow: wrap;
			justify-content: center;
			.btn-custom {
				margin-bottom: 5px;
				padding: 10px 44px;
			}
		}
	}
	.chatSend {
		.col-md-2 {
			padding-left: 0;
		}
	}
	.suche {
		.sucheBox {
			.onpic {
				font-size: 12px;
				.col-3 {
					padding-left: 0;
				}
			}
		}
	}
}
@media only screen and (max-width:991px) {
	body {
		padding-bottom: 30px;
	}
	html {
		padding-bottom: 30px;
	}
	.menu-toggler {
		display: block;
		position: absolute;
		right: 25px;
		top: 13px;
		font-size: 30px;
		color: $color_23;
	}
	.header {
		.links {
			margin-top: 25px;
			justify-content: center;
			a {
				margin-right: 15px;
				margin-left: 15px;
			}
		}
	}
	.sidebar {
		display: none;
	}
	.dashboard {
		.userImages {
			img {
				padding: 5px;
			}
		}
	}
	.footer {
		margin: 0;
		text-align: center;
		.text-end {
			text-align: center !important;
		}
		img {
			margin-bottom: 15px;
		}
		a {
			margin-left: 3px;
			margin-right: 3px;
		}
		.newMessages {
			position: fixed;
			top: auto;
			bottom: 0;
		}
	}
	.shop {
		.shopBox.code {
			.btn {
				margin-top: 15px;
				width: 100%;
			}
		}
	}
	.suche {
		.sucheBox {
			.onpic {
				font-size: 12px;
				.col-3 {
					padding-left: 0;
				}
			}
		}
	}
	.profil {
		.profilActions {
			flex-flow: wrap;
			justify-content: center;
			.btn-custom {
				margin-bottom: 5px;
				padding: 10px 20px;
			}
		}
	}
	.chat {
		.head {
			i {
				font-size: 20px;
			}
		}
		.chatBox {
			height: 400px;
			.bubble {
				max-width: 80%;
			}
		}
	}
	.row.reversed {
		flex-direction: column-reverse;
	}
	.hidden-sm {
		display: none;
	}
	.show-mobile {
		display: block;
	}
}
