@import 'variables';
@keyframes horizontalProgressBar {
	0% {
		width: 0%;
	}
	20% {
		width: 10%;
	}
	30% {
		width: 15%;
	}
	40% {
		width: 18%;
	}
	50% {
		width: 20%;
	}
	60% {
		width: 22%;
	}
	100% {
		width: 100%;
	}
}
.animated {
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-user-select: none;
}
.yt-loader {
	-webkit-animation-name: horizontalProgressBar;
	animation-name: horizontalProgressBar;
	-webkit-animation-timing-function: ease;
	animation-timing-function: ease;
	animation-iteration-count: infinite;
	background: $theme_color;
	height: 3px;
	left: 0;
	top: 0;
	width: 0%;
	z-index: 9999;
	position: relative;
	&:after {
		display: block;
		position: absolute;
		content: '';
		box-shadow: $theme_color 1px 0 6px 1px;
		opacity: 0.5;
	}
}
