@import 'variables';
$color_1: #fff;

/* body div.ratingbox div.selection a{width:30px;height:30px;display:inline-block;cursor:default;background:url(../pic/stars30.png) no-repeat center 0;text-decoration:none} */
/* body div.ratingbox div.progress{width:33px;height:34px;display:none;background:url(../pic/progress2.gif) no-repeat center center} */
body {
	div.ratingbox {
		position: relative;
		div.selection {
			a.over {
				background-position: center -60px;
			}
			a {
				&:hover {
					background-position: center -30px;
				}
			}
			a.out {
				background-position: center 0;
			}
			a.in {
				background-position: center -30px;
			}
		}
	}
}
.fast-chat {
	.fast-chat-number2 {
		background: $theme_color;
		color: $color_1;
		font-size: 12px;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		line-height: 20px;
		display: inline-block;
		text-align: center;
		margin-left: 5px;
	}
}
.clear {
	clear: both;
}
