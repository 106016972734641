@import 'variables';
$color_1: rgba(0, 0, 0, .21);
$color_3: #fff;
$color_4: #000;
$color_5: rgba(0, 0, 0, .6);
$color_6: rgba(60, 60, 60, 1);
$color_7: rgba(83, 83, 83, 1);
$color_8: rgba(28, 28, 28, 1);
$color_9: rgba(0, 0, 0, .5);
$color_10: rgba(0, 0, 0, .44);
$color_11: #aaa;

.start {
	background-size: cover;
	background-position: center;
	.logo {
		margin: 0 auto;
		width: 150px;
		padding: 35px 0px;
		img {
			width: 100%;
		}
	}
	.menu {
		position: absolute;
		right: 35px;
		top: 35px;
		i {
			color: $color_1;
			font-size: 45px;
			cursor: pointer;
		}
	}
	.start_content {
		text-align: center;
		color: $theme_color;
		padding: 100px 0px;
		padding-bottom: 250px;
		h2 {
			font-size: 40px;
			margin-bottom: 25px;
		}
		.btn-custom {
			width: 100%;
			color: $color_3;
			padding: 10px 35px;
			margin-bottom: 15px;
			text-transform: uppercase;
			text-align: center;
			position: relative;
			.border {
				border: 1px solid rgba(255, 255, 255, .29) !important;
				border-radius: 50%;
				padding: 8px 14px;
				position: absolute;
				left: 0;
				top: 0;
				i {
					padding-top: 6px;
				}
			}
		}
		.btn-fb {
			img {
				width: 15px;
				padding: 2px;
			}
		}
		.btn-google {
			img {
				width: 21px;
			}
		}
		.testi {
			small {
				color: $color_4;
				font-weight: bold;
				font-size: 12px;
				display: block;
				margin-bottom: 25px;
				margin-top: 15px;
			}
			img {
				width: 100%;
				border-radius: 50%;
			}
			.white-box {
				box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16);
				border-radius: 15px;
				padding: 15px;
				background: #fff;
				color: $color_5;
				text-align: left;
				strong {
					font-size: 14px;
					color: $color_4;
					font-weight: bold;
				}
			}
		}
	}
}
.start.whiter {
	background-size: cover;
	background-position: center;
}
.start.sub {
	background-position: 0px -320px;
}
.btn-custom {
	border-radius: 26px;
	padding: 10px 35px;
}
.btn-red {
	background: $theme_color;
	border: 1px solid rgba(255, 255, 255, 1);
	color: $color_3;
	&:hover {
		background: $theme_color;
		border: 1px solid rgba(255, 255, 255, 1);
		color: $color_3;
	}
	&:focus {
		background: $theme_color;
		border: 1px solid rgba(255, 255, 255, 1);
		color: $color_3;
	}
	&:active {
		background: $theme_color;
		border: 1px solid rgba(255, 255, 255, 1);
		color: $color_3;
	}
}
.btn-green {
	background: #4D8F56;
	border: 1px solid rgba(255, 255, 255, 1);
	color: $color_3;
	&:hover {
		background: #4D8F56;
		border: 1px solid rgba(255, 255, 255, 1);
		color: $color_3;
	}
	&:focus {
		background: #4D8F56;
		border: 1px solid rgba(255, 255, 255, 1);
		color: $color_3;
	}
	&:active {
		background: #4D8F56;
		border: 1px solid rgba(255, 255, 255, 1);
		color: $color_3;
	}
}
.btn-fb {
	background: rgba(78, 113, 168, 1);
	border: 1px solid rgba(255, 255, 255, 1);
	&:hover {
		background: rgba(78, 113, 168, 1);
		border: 1px solid rgba(255, 255, 255, 1);
	}
	&:focus {
		background: rgba(78, 113, 168, 1);
		border: 1px solid rgba(255, 255, 255, 1);
	}
	&:active {
		background: rgba(78, 113, 168, 1);
		border: 1px solid rgba(255, 255, 255, 1);
	}
}
.btn-google {
	background: rgba(234, 234, 234, 1);
	border: 1px solid rgba(255, 255, 255, 1);
	color: $color_6 !important;
	&:hover {
		background: rgba(234, 234, 234, 1);
		border: 1px solid rgba(255, 255, 255, 1);
		color: $color_6 !important;
	}
	&:focus {
		background: rgba(234, 234, 234, 1);
		border: 1px solid rgba(255, 255, 255, 1);
		color: $color_6 !important;
	}
	&:active {
		background: rgba(234, 234, 234, 1);
		border: 1px solid rgba(255, 255, 255, 1);
		color: $color_6 !important;
	}
}
.btn-white {
	background: #fff;
	color: $theme_color;
	&:hover {
		background: #fff;
		color: $theme_color;
	}
	&:focus {
		background: #fff;
		color: $theme_color;
	}
	&:active {
		background: #fff;
		color: $theme_color;
	}
}
.btn-grey {
	background: rgba(214, 224, 230, 1);
	color: $color_7;
	&:hover {
		background: rgba(214, 224, 230, 1);
		color: $color_7;
	}
	&:focus {
		background: rgba(214, 224, 230, 1);
		color: $color_7;
	}
	&:active {
		background: rgba(214, 224, 230, 1);
		color: $color_7;
	}
}
.redBack {
	background: $theme_color;
	color: $color_3;
}
.section {
	padding: 100px 0px;
}
.now {
	color: $color_3;
	text-align: center;
}
.lighter {
	font-weight: lighter;
	font-size: 14px;
}
.iconBox {
	background: #fff;
	box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16);
	color: $theme_color;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 35px;
	margin: 0 auto;
	position: relative;
	img {
		width: 60%;
	}
}
.iconBox.margin {
	margin-bottom: -50px;
	margin-top: 50px;
}
.why {
	text-align: center;
	font-weight: lighter;
	font-size: 19px;
	color: $color_8;
	h2 {
		margin: 0 auto;
		// width: 350px;
		margin-bottom: 15px;
	}
	h4 {
		margin: 0;
		margin-top: 50px;
	}
	.userImages {
		margin: 50px 0px;
		display: flex;
		flex-flow: wrap;
		img {
			width: 16.66667%;
			border-radius: 10px;
			padding: 5px;
		}
	}
}
.nowreg {
	text-align: center;
	.btn {
		margin: 0 auto;
		font-weight: bold;
		margin-top: 25px;
	}
}
.footer {
	padding: 15px;
	text-align: center;
	a {
		font-size: 12px;
		color: $color_9;
		padding-left: 10px;
		padding-right: 10px;
		text-decoration: none;
	}
}
.register {
	padding: 250px 0px;
	text-align: center;
	h2 {
		margin-bottom: 15px;
		font-weight: lighter;
	}
	.btn {
		width: 100%;
		text-transform: uppercase;
		margin-bottom: 5px;
	}
	small {
		color: $color_10;
		display: block;
		margin-top: 25px;
	}
	.steps {
		display: none;
	}
	.pink {
		color: $theme_color;
		font-weight: bold;
	}
	.btn-green {
		margin-top: 25px;
	}
	.emailicon {
		width: 180px;
	}
}
.faq-section {
	h4 {
		a {
			color: $theme_color;
			text-decoration: none;
			border-bottom: 1px solid #ccc;
			display: block;
			padding-bottom: 15px;
			margin-bottom: 15px;
			font-size: 16px;
		}
	}
	.answer {
		border: 1px solid #d5d8db;
		background: #f1f1f1;
		border-radius: 6px;
		margin: 8px 8px 20px 8px;
		padding: 15px;
		font-size: 14px;
	}
}
.icon-right-open {
	&::before {
		content: 'Â»';
		display: inline-block;
		margin-right: 10px;
		color: $color_11;
	}
}
.register-city-select {
	background: #fff;
	border-radius: 2px 2px 8px 8px;
	text-align: left;
	list-style: none;
	margin: 0;
	padding: 0;
	border: 1px solid #cdd4d9;
	border-top: none;
	li {
		padding: 5px 0 5px 15px;
	}
}
@media only screen and (max-width:480px) {
	.start {
		padding-bottom: 350px;
		background-position: -70px 0px;
		.menu {
			i {
				font-size: 25px;
			}
			top: 58px;
			right: 30px;
		}
		.start_content {
			position: absolute;
			left: 0;
			right: 0;
			width: 93%;
			margin: auto;
			padding-top: 220px;
			h2 {
				color: $color_4;
				font-weight: lighter;
				font-size: 35px;
				margin-bottom: 25px;
			}
		}
	}
	.iconBox.margin {
		margin-top: 320px;
	}
	.iconBox.margin.interactive-features {
		margin-top: 100px;
	}
	.iconBox.margin.Unmatched-Dating {
		margin-top: 100px;
	}
	.now.section {
		padding-top: 80px;
	}
	.now {
		h2 {
			font-size: 30px;
		}
	}
	.section {
		padding: 50px 0px;
	}
	.why {
		.userImages {
			img {
				width: 33.3333%;
			}
			margin: 20px 0px;
		}
		h4 {
			margin-bottom: 10px;
		}
	}
	.nowreg {
		.btn {
			padding: 10px 55px;
		}
	}
	.footer {
		a {
			padding: 0px 3px;
		}
	}
	.start.whiter {
		background: none;
		padding-bottom: 100px;
	}
	.register {
		padding: 100px 0px;
		h2 {
			font-size: 35px;
		}
		.step8 {
			h2 {
				font-size: 27px;
			}
		}
	}
	.start.sub {
		padding-bottom: 0px;
		background-position: 0px -40px;
	}
	.btn-red-login{
		padding: 8px 18px;
		font-size: 14px;
	}
}
